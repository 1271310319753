import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {PeticionGet,PeticionPost} from '../Peticiones/Index';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import LoadingSpinner from '../LoadingSpinner';
import '../App.css';

const useStyles = makeStyles((theme) => ({
    Formulario: {
        margin: theme.spacing(1),
      },
     InputSpace:{
       marginBottom: 10,
     },
     Boton: {
      textAlign: "right",
    }
  }));


const Formulario = () =>{

    const classes = useStyles();

    const [departamento, setDepartamento] = useState('');
    const [usuario, setUsuario] = useState('');
    const [dataDepartamento, setDataDepartamento] = useState('');
    const [dataPersonal, setDataPersonal] = useState('');
    const [equipo, setEquipo] = useState('');
    const [dataEquipo, setDataEquipo] = useState('');
    const [dataCriticidad, setDataCriticidad] = useState('');
    const [descripcion, setDataDescripcion] = useState('');
    const [loading, setLoading] = useState(false);
    const [validacion, setValidacion] = useState('');


    useEffect(() => {
        async function fetchMyAPI() {
          let response = await PeticionGet('departamento');
          //response = await response.json()
          setDataDepartamento(response);
          setLoading(true);
        }
        fetchMyAPI();

      }, [])

    const handleChangeDepartamento = (event) => {
      setDepartamento(event.target.value);
      async function fetchMyAPI() {
        let response = await PeticionGet('personal?id='+event.target.value);
        setDataPersonal(response);
      }
      fetchMyAPI();
    };

    const handleChangeUsuario = (event) => {
      setUsuario(event.target.value);
      async function fetchMyAPI() {
        let response = await PeticionGet('equipo?id='+event.target.value);
        setDataEquipo(response[0].codigo);
        setEquipo(response[0]);
      }
      fetchMyAPI();
    };

    const handleChangeCriticidad = (event) => {
      setDataCriticidad(event.target.value);
    };

    const handleChangeDescripcion = (event) =>{
      setDataDescripcion(event.target.value);
    }

    const SubmitData =() =>{
      setLoading(false);
    
      if(departamento === '' || usuario === '' || equipo.id === '' || dataCriticidad === '' || descripcion === ''){
        alert("Debe ingresar todo los datos");
        setLoading(true);
        return;
      }

      async function fetchMyAPI() {
        let data = {
          departamento: departamento,
          id_personal: usuario,
          equipo: equipo.id,
          id_criticidad: dataCriticidad,
          descripcion: descripcion,
        }
        let response = await PeticionPost('CrearTicket',data);
        
        if (response){

          Swal.fire({
            icon: 'success',
            text: 'La solicitud fue creada correctamente',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          })

        }
      }
      fetchMyAPI();
    }

    return(
      <div className="Formulario">
        {loading ?  
        
        <form className= {classes.Formulario} >
          
        <InputLabel id="demo-simple-select-filled-label">Departamento</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={departamento}
          onChange={handleChangeDepartamento}
          fullWidth
          variant="outlined"
          className= {classes.InputSpace}
        >
       
       {dataDepartamento.map((DepartamentoRow,index) => {
           return <MenuItem value={DepartamentoRow.id}  key={index}>{DepartamentoRow.nombre}</MenuItem>;
          })}
        </Select>

        <InputLabel id="demo-simple-select-filled-label">Personal</InputLabel>

        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={usuario}
          onChange={handleChangeUsuario}
          variant="outlined"
          fullWidth
          className= {classes.InputSpace}
        >

        {dataPersonal ?  
        
        dataPersonal.map((DepartamentoRow,index) => {
           return <MenuItem value={DepartamentoRow.id}  key={index}>{DepartamentoRow.nombre}</MenuItem>;
          }) 
          
          : 
          
          '' }

        </Select>

        <TextField
          inputProps={{
            readOnly: true,
            disabled: true,
          }}
          required
          fullWidth={true}
          label="Serial del Equipo"
          name="name"
          variant="filled"
          value= {dataEquipo}
          className= {classes.InputSpace}
        />

      <InputLabel id="demo-simple-select-outlined-label">Criticidad</InputLabel>
        <Select
         labelId="demo-simple-select-filled-label"
         id="demo-simple-select-filled"
         value={dataCriticidad}
         onChange={handleChangeCriticidad}
         variant="outlined"
         fullWidth
         className= {classes.InputSpace}
        >
          <MenuItem value={1}>Alto</MenuItem>
          <MenuItem value={2}>Medio</MenuItem>
          <MenuItem value={3}>Bajo</MenuItem>
        </Select>

        <TextField
            required
            id="outlined-required"
            label="Descripcion"
            variant="outlined"
            width="100%"
            fullWidth
            multiline
            rows={2}
            rowsMax={4}
            onChange={handleChangeDescripcion}
            className= {classes.InputSpace}
            value={descripcion}
            />
        <div className= {classes.Boton}>

          <Button variant="outlined" color="primary"  align="center" onClick={SubmitData}>
            Registrar
          </Button>
       </div>
        </form>
        
        :
        
        <div> <LoadingSpinner/> </div>}
     </div>
    );
}

export default Formulario;
