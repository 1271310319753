import React from 'react';
import {Switch,Route} from "react-router-dom";
import Login from '../Login';
import tickets from '../Ticket';
import Container from '@material-ui/core/Container';

    const Rutas = () =>{
        return(
                <div className="Body">
                      <Container maxWidth="lg">
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route path="/tickets" component={tickets} />   
                    </Switch>
                    </Container>
                </div>
        );
    }

export default Rutas;
