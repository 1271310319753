import React from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import Header from './Layout/Header';
import Rutas from './Layout/Rutas';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#DA251C',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });
  
const Home = () =>{

    return(
        <div>
            <Router>
            <ThemeProvider theme={theme}>
            <Header/>
            <Rutas/>
            </ThemeProvider>
            </Router>
        </div>
    );
}

export default Home;
