import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      float:'right',
    },
    Navbar:{
      marginBottom: 20,
     // boxShadow: 'none',
      backgroundColor: '#ffffff',
    }
  }));

    const Header = () =>{
        const classes = useStyles();
        return(
                <div className="Head">
                    <CssBaseline/>
                    <AppBar position="static"  className={classes.Navbar} >
                        <Toolbar>
                        <Grid item  xs={8} sm= {8} md={10} lg={10}>
                        </Grid>
     
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                        
                        </Grid>
                        </Toolbar>

                    </AppBar>
                </div>
        );
    }

export default Header;
