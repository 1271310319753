import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Formulario from './Formulario';
import LoadingSpinner from '../LoadingSpinner';
import {PeticionGet,PeticionPost} from '../Peticiones/Index';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#DA251C',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  boton:{
    marginBottom: 25,
  }
});

export default function Tablas() {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [ticketCreados, setTicketCreados] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await PeticionGet('TicketFind');
      setTicketCreados(response);
     // setLoading(true);
    }
    fetchMyAPI();

  }, [])

  if(ticketCreados){
    console.log(ticketCreados)
  }


  return (

<div className ="Tickets">

{ticketCreados ?     
<div>

  <Button variant="outlined" color="primary" className={classes.boton}  onClick={handleClickOpen} >
    Nuevo Ticket
  </Button>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">#Ticket</StyledTableCell>
            <StyledTableCell align="center">Departamento</StyledTableCell>
            <StyledTableCell align="center">Usuario</StyledTableCell>
            <StyledTableCell align="center">Cod. Equipo</StyledTableCell>
            <StyledTableCell align="center">Descripcion</StyledTableCell>
            <StyledTableCell align="center">Prioridad</StyledTableCell>
            <StyledTableCell align="center">Criticidad</StyledTableCell>
            <StyledTableCell align="center">Fecha Solicitud</StyledTableCell>
            <StyledTableCell align="center">Fecha de Cierre</StyledTableCell>
            <StyledTableCell align="center">¿Status?</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ticketCreados.map((row) => (
            <StyledTableRow key={row.id_ticket}>
              <StyledTableCell component="th" scope="row">
              TCK#000000{row.id_ticket}
              </StyledTableCell>
              <StyledTableCell align="center">{row.nombre_departamento}</StyledTableCell>
              <StyledTableCell align="center">{row.nombre_personal}</StyledTableCell>
              <StyledTableCell align="center">{row.codigo}</StyledTableCell>
              <StyledTableCell align="center">{row.descripcion}</StyledTableCell>
              <StyledTableCell align="center">{row.nombre_prioridad}</StyledTableCell>
              <StyledTableCell align="center">{row.nombre_criticidad}</StyledTableCell>
              <StyledTableCell align="center">{row.fecha_solicitud}</StyledTableCell>
              {row.fecha_cierre ?
                <StyledTableCell align="center">{row.fecha_cierre}</StyledTableCell>
                : 
                <StyledTableCell align="center">Pendiente</StyledTableCell>
              }
            
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Dialog 
      open={open} 
      onClose={handleClose} 
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth = {'xs'}
    >
        <DialogTitle id="form-dialog-title" >Nuevo Ticket</DialogTitle>
        <DialogContent>
           <Formulario/>
        </DialogContent>
      </Dialog>
</div>
: 
          
<LoadingSpinner/> }

  </div>
  );
}