import axios from 'axios';

   export const PeticionGet = async (props) =>{
    try {
        let res = await axios({
             url: 'http://localhost/issm-backend/public/'+props,
             method: 'get',
         })
         return res.data
     }
     catch (err) {
         console.error(err);
     }
 }

 export const PeticionPost = async (props,data) =>{
    try {
        let res = await axios({
             url: 'http://localhost/issm-backend/public/'+props,
             method: 'post',
             data: data,
         })
         return res.data
     }
     catch (err) {
         console.error(err);
     }
 }

export default PeticionGet;
