import React, {useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'    
import {PeticionPost} from '../Peticiones/Index';
import LoadingSpinner from '../LoadingSpinner';

const useStyles = makeStyles((theme) => ({

  boton: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 12,
  },
  Ubicacion:{
    marginTop: '10%',
  },
  logo:{
    width:80,
    height:80,
    marginTop: 20,
    borderRadius: 50,
  },
  PaperMargen:{
    marginBottom:300,
  },
}));

    const Login = (props) =>{
     
      const classes = useStyles();
      const [usuario, setUsuario] = useState('');
      const [password, setPassword] = useState('');
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState('');


      const handleChangeUsuario = (event) => {
        setUsuario(event.target.value);
      }

      const handleChangePassword = (event) => {
        setPassword(event.target.value);
      }


     useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          ValidateUser();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    },);

      const ValidateUser = async ()=>{

        setLoading(true);

        let data = {
          nombre: usuario,
          password: password,
        }
        
      let response = await PeticionPost('UserAuthentication',data);

      if (typeof response !== 'undefined' && response.length > 0) {
        setLoading(false);
        props.history.push("/Tickets")
        } else {
         setLoading(false);
          setError('Usuario o Contraseña incorrectos.')
        }
      }
    
        return(
        <div >
        {loading ?  

        <div> 
          <LoadingSpinner/>
        </div>
        
        :

        <Grid container spacing={3} className= {classes.Ubicacion}>
        <Grid item xs={2} sm={2} md={4} lg={4}>
        </Grid>

        <Grid item xs={8} sm={8} md={4} lg={4} align="center">
            <Paper elevation={10}     
              spacing={0}
              direction="column"
              justify="center"
              >
                    <Grid item xs = {8} sm = {8} md = {8} lg = {8}  >
                    <img src={require('../imagenes/login2.png')} className={classes.logo} alt="inicio de sesion logo" />
                    </Grid>
                    <Grid item xs = {8} sm = {8} md = {8} lg = {8}>  
                        <TextField id="input-with-icon-grid" label="Usuario"  fullWidth onChange={handleChangeUsuario} />
                    </Grid>
                    <Grid item  xs = {8} sm = {8} md = {8} lg = {8} > 
                      <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        fullWidth
                        onChange={handleChangePassword}
                      />
                    </Grid>
                    
                    <Grid item  xs = {8} sm = {8} md = {8} lg = {8} >
                      <div style={{ color: 'red', fontWeight: 'bold'}}>
                        {error}
                      </div> 
                    </Grid>

                    <Grid item  xs = {8} sm = {8} md = {8} lg = {8}> 
                    <Button variant="outlined" color="primary"  className={classes.boton} onClick={ValidateUser}>
                     Iniciar Sesion
                    </Button>
                    </Grid>
            </Paper>
        </Grid>

        <Grid item xs={2} sm={2} md={4} lg={4}>
        </Grid>

        </Grid>
    }
         </div>
        );
    }

export default withRouter (Login);
